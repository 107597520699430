<template>
  <el-container>
    <aside-bookdetail :del="isDel" @change="asideData($event)" @changeame="changeName($event)"></aside-bookdetail>
    <el-main>
      <div class="centerwrap">
        <fold-top></fold-top>
        <div class="centerbox">
          <div class="topbox">
            <h2 class="titleh2" v-text="content.Section"></h2>
            <el-button
              v-if="isEditor==true"
              type="primary"
              plain
              size="mini"
              class="savebtn"
              @click="saveBook"
            >保 存</el-button>
            <span class="iconbox" v-if="isEditor==false">
              <i class="iconfont iconshanchu3" @click="isDel=!isDel" v-if="iscreator"></i>
              <i>
                <el-popover
                  placement="bottom"
                  title="版权"
                  width="200"
                  trigger="hover"
                  content="归作者所有"
                >
                  <i slot="reference" class="iconfont icontishi"></i>
                </el-popover>
              </i>
              <!-- <i class="iconfont iconbianzu6"></i>
              <i class="iconfont iconxingzhuang4"></i> -->
              <i class="iconfont iconbianji2" @click="showEditor" :class="isEditor==true&&'active'" v-if="iscreator"></i>
            </span>
            <span class="iconbox" v-else>
              <i
                @click="handelChangeMavon('Editor')"
                class="iconfont iconqiehuan"
              >切换到 {{Editor==1? 'markdown' :'tinymce'}}</i>
              <i class="iconfont iconbianzu5" @click="showEditor">查看</i>
            </span>
          </div>
          <div class="textbox" v-if="isEditor==false" v-html="Content"></div>
          <div class="editor" v-else>
            <tinymce-editor
              v-model="Content"
              :disabled="disabled"
              ref="editor"
              :theight="curHeight"
              v-if="Editor==1"
              @onBlur="saveBook"
            ></tinymce-editor>
            <mavon-editor
              :value="Markdown"
              ref="meditor"
              @input="Markdown=$event"
              v-else
              :theight="curHeight"
              @onBlur="saveBook"
            ></mavon-editor>
          </div>
          <div class="alertBox" v-if="asidedata.Cover && flag==2">
            <div class="leftimg">
              <div class="imgbox">
                <img :src="asidedata.Cover" alt />
              </div>
            </div>
            <div class="righttext">
              <h2 class="titleh2">
                <span class="text">{{asidedata.Bookname}}</span>
              </h2>
              <p class="textp" v-html="asidedata.Desc"></p>
              <div class="leftdl">
                <dl class="msg">
                  <dt>售价：</dt>
                  <dd class="blue">￥{{Discount}}</dd>
                  <dd class="dec" v-if="asidedata.Price!==0">￥{{asidedata.Price}}</dd>
                </dl>
              </div>
              <div class="btnbox">
                <el-button type="primary" size="medium">购 买</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { booksSectionRequest, booksArticleRequest } from "@/api/project.js";
import FoldTop from "@/components/Foldtop/foldTop";
import AsideBookdetail from "../components/Asidebookdetail.vue";
import TinymceEditor from "@/components/tinymceEditor"; //编辑器
import MavonEditor from "@/components/mavonEditor"; //编辑器
import { TurndownService } from "@/assets/js/turndown.js";

import Prism from "prismjs";
import "prismjs/themes/prism-coy.css";

export default {
  components: {
    FoldTop,
    AsideBookdetail,
    TinymceEditor, //编辑器
    MavonEditor //编辑器
  },
  data() {
    return {
      content: {},
      Content: "",
      isDel: true,
      isEditor: false,
      disabled: false,
      inittac: {
        height: "500px"
      },
      Editor: 0, //文章默认编辑器
      editorIndex: 0, //整本书默认编辑器
      Markdown: "",
      curHeight: 0,
      flag: 0,
      asidedata: {},
      iscreator:''
    };
  },
  created() {
    // this.getBookSection(this.Id);
    // this.editorIndex=this.$store.state.editorIndex;
    this.setEditorHeight();
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    async getBookSection(secid) {
      try {
        var _this = this;
        const result = await booksSectionRequest({
          secid: secid
        });
        if (result.status == 200) {
          if (result.data) {
            this.content = result.data.content;
            this.Content = result.data.content.Content;
            this.Editor = result.data.content.Editor;
            this.Markdown = result.data.content.Markdown;
            this.flag = result.data.flag;
          }
          setTimeout(() => {
            Prism.highlightAll();
          }, 100);
        }
      } catch (err) {}
    },
    handelTab(index, a) {
      this[a] = index;
    },
    showEditor() {
      this.isEditor = !this.isEditor;
      if (this.isEditor == false && this.Editor == 2) {
        this.TmacChangeMavon(1);
      }
      this.Editor = this.Editor === 0 ? this.editorIndex : this.Editor;
      setTimeout(() => {
        Prism.highlightAll();
      }, 100);
    },
    TmacChangeMavon(editor, item) {
      if (item) this[item] = editor;
      // editor=2 是  markdown  既markdown 变成 tinymac格式
      // editor=1 是  tinymce   tinymac 变成 markdown
      var _this = this;
      if (editor === 1) {
        // 既markdown 变成 tinymac格式
        var marked = require("marked");
        var rendererMD = new marked.Renderer();
        marked.setOptions({
          renderer: rendererMD,
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }); //基本设置
        _this.Content = marked(_this.Markdown);
      } else {
        //tinymac 变成 markdown
        var turndownService = new TurndownService(); //Tmac变成Mavon格式
        _this.Markdown = turndownService.turndown(_this.Content);
      }
    },
    handelChangeMavon(Editor) {
      var _this = this;
      this.$confirm("切换编辑器后，部分样式可能会丢失, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.TmacChangeMavon(_this[Editor] === 2 ? 1 : 2, Editor);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消切换"
          });
        });
    },
    async saveBook() {
      try {
        console.log(this.Editor);
        var oData = {};
        if (this.Editor === 2) {
          //markdown
          this.TmacChangeMavon(1);
        }
        //markdown
        oData = {
          tid: this.tid,
          secid: this.Id,
          editor: this.Editor,
          section: this.content.Section,
          content: this.Content,
          markdown: this.Editor === 2 ? this.Markdown : ""
        };

        const result = await booksArticleRequest(oData);
      } catch (err) {
        console.log(err);
      }
      console.log("ok");
    },
    setEditorHeight() {
      var h =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.curHeight = h - 61; //减去页面上固定高度height
      console.log(this.curHeight);
    },
    asideData(data) {
      console.log(data);
      this.editorIndex = data.book.Editor;
      this.asidedata = data.book;
      this.iscreator=data.iscreator
    },
    changeName(name){
        this.content.Section=name
    }
  },
  computed: {
    secid() {
      return this.$store.state.secid;
    },
    Id() {
      return this.$route.query.Id || 0;
    },
    tid() {
      return this.$route.query.tid || 0;
    },
    Discount() {
      if (this.asidedata.Price == 0) {
        return (this.asidedata.Discount = "免费");
      } else {
        return (this.asidedata.Discount * this.asidedata.Price) / 10;
      }
    }
  },
  watch: {
    Id: {
      handler(newName, oldName) {
        if (newName && newName !== oldName) {
          this.getBookSection(newName);
        }
      },
      immediate: true
    }
  }
};
</script>
<style  lang="less" scoped>
.textbox{
    /deep/p{
    margin-top: 1em;
    margin-bottom: 1em;
  }
  /deep/ul,  /deep/li  {
  list-style: inherit;
}
/deep/h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
}
/deep/em, /deep/i {
font-style:italic!important;
}
/deep/ol{
  display: block;
      list-style: decimal;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px
}
}

.el-container {
  flex-direction: row;
  height: 100%;
  .el-main {
    padding: 0;
  }
}
.centerwrap {
  position: relative;
  // margin: -20px;
}
.centerbox {
  position: relative;
}
.alertBox {
  position: absolute;
  width: 80%;
  height: 375px;
  left: 50%;
  top: 120px;
  margin-left: -40%;
  background: #fff;
  z-index: 10;
  -webkit-box-shadow: 0px 0px 18px #7f8190;
  box-shadow: 0px 0px 18px #7f8190;
  display: flex;
  padding: 60px;
  box-sizing: border-box;
  .leftimg {
    width: 195px;
    margin-right: 30px;
    .imgbox {
      width: 195px;
      height: 255px;
      border: 1px solid #c3d7ff;
      border-radius: 4px;
      overflow: hidden;
      padding: 5px;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tittleh2 {
      font-size: 14px;
      color: #2f95e3;
      text-decoration: underline;
    }
  }
  .righttext {
    flex: 1;
    .titleh2 {
      font-size: 20px;
      margin-bottom: 10px;
      .text {
        min-width: 80px;
      }
      .iconfont {
        color: #11adcf;
        margin-left: 15px;
      }
    }
    .textp {
      font-size: 14px;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; //溢出隐藏
      height:90px;
    }
    .leftdl {
      float: left;
      margin-top: 20px;
    }
    .msg {
      margin-bottom: 10px;
      dt,
      dd {
        font-size: 15px;
        display: inline-block;
      }
      dd {
        color: #888;
        vertical-align: middle;
        margin-right: 10px;
      }
      .black {
        color: #222;
      }
      .blue {
        color: #3377ff;
      }
      .dec {
        text-decoration: line-through;
      }
      img {
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 30px;
      }
    }
    .btnbox {
      margin: 75px 0 0;
      /deep/.el-button {
        margin-right: 10px;
         padding: 10px 60px;
      }
      .bluebtn {
        border: 1px solid #2f95e3;
        color: #2f95e3;
        font-weight: normal;
           
      }
    }
  }
}
.topbox {
  border-bottom: 1px solid #ccc;
  line-height: 40px;
  padding: 10px 20px 10px 50px;
  height: 40px;
  display: flex;
  .titleh2 {
    font-size: 20px;
    flex: 1;
  }
  .iconbox {
    width: 250px;
    & > i {
      float: right;
      margin-left: 15px;
      color: #11adcf;
      font-size: 14px;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
      &.active {
        color: #3385ff;
      }
    }
  }
}
.textbox {
  padding: 20px;
  .textp {
    font-size: 14px;
    line-height: 24px;
    text-indent: 2em;
    margin-bottom: 10px;
  }
}
/deep/.collapse {
  i {
    color: #aaa;
  }
}
/deep/.tox-tinymce {
  border-left: none;
  border-right: none;
  border-top: none;
}
.changeedit {
  cursor: pointer;
  text-align: right;
  margin: 20px;
  i {
    color: #11adcf;
  }
}
.savebtn {
  margin-right: -40px;
  height: 30px;
  margin-top: 6px;
  position: relative;
  z-index: 10;
}
/deep/.mavon-editor {
  .v-note-wrapper.shadow {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
  }
}
</style>
