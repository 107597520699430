<template>
   <div class="collapse" @click="handleCollapse">
      <i class="iconzhedie1 iconfont"></i>
    </div>
</template>
<script>
export default {
  name: "FoldTop",
  data() {
    return {
     
    };
  },
  created() {},

  methods: {
    handleCollapse(index) {
      this.$store.commit("setCollapse", !this.collapse);
    }
  },
  computed: {
    collapse (){
      return this.$store.state.collapse;
    }
  }
};
</script>
<style lang="less" scoped>
.collapse{
  position: absolute;
  z-index: 100;
  font-size: 16px;
  top: 18px;
  left: 15px;
  cursor: pointer;
  i{
    font-size: 14px;
    color: #fff;
  }
}
</style>