<template>
  <el-aside class="asidesecond" :class="collapse && 'hideaside'">
    <el-input placeholder="搜索" suffix-icon=" ">
      <i slot="suffix" class="iconfont iconxingzhuang2"></i>
    </el-input>
    <div class="toptxt">
      <h2>
        <i class="iconfont icontushuguan"></i>{{book.Bookname}}
      </h2>
    </div>
    <div class="centerbox">
      <div class="btnbox">
        <i class="iconfont iconxingzhuang3"></i>
        <span class="newml" @click="handelAddName" v-if="iscreator==1">新增目录</span>
      </div>
      <el-tree
        :data="section"
        :props="defaultProps"
        node-key="Id"
        :expand-on-click-node="false"
        :default-expanded-keys="[13]"
      >
        <span
          class="custom-tree-node"
          slot-scope="{ node, data }"
          :class="data.Id==Id? 'activenode':''"
        >
          <span class="text">
            <router-link :to="{name:'bookDetail',params:{name:name},query:{Id:data.Id}}" tag="span">{{ node.label }}</router-link>
            <i class="read" v-if="book.Price > 0 && data.Trials==1 && data.isbuyer==0">试读</i>
          </span>
          <span class="morbox" v-if="iscreator==1">
            <span @mouseover="showtext($event)" @mouseout="hidetext($event)">
              <i class="iconfont icongengduo more"></i>
              <ul class="moreul">
                <li @click="() => handelAddName(node, data,'sub')">新增子目录</li>
                <li @click="() => handelAddName(node, data)">目录重命名</li>
                <li @click="() => handelRemoveName(node, data)">目录删除</li>
                <li>文章编辑</li>
              </ul>
            </span>
          </span>
        </span>
      </el-tree>
    </div>
  </el-aside>
</template>
<script>
import {
  booksNameRequest,
  booksSectionDelRequest,
  booksSectionAddRequest
} from "@/api/project.js";
export default {
  props: {
    del: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      section: [],
      book: {},
      defaultProps: {
        children: "Child",
        label: "Section"
      },
      sub: "",
      iscreator:''
    };
  },
  created() {
    this.getBookName();
    var _this = this;
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "active";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    async getBookName() {
      try {
        const result = await booksNameRequest({
          name: this.name
        });
        if (result.status == 200) {
          if (result.data) {
            this.section = result.data.section || [];
            this.book = result.data.book || {};
            this.iscreator=result.data.iscreator
            this.isbuyer=result.data.isbuyer
            this.$emit('change', result.data)
          }
        }
      } catch (err) {}
    },
    async removeName(node, data, cb) {
      try {
        const result = await booksSectionDelRequest({
          bookid: data.Bookid,
          secid: data.Id
        });

        if (result.status == 200) {
          this.$message({
            type: "success",
            message: `${result.message}`
          });
          cb && cb();
        }
      } catch (err) {}
    },
    handelRemoveName(node, data) {
      var _this = this;
      this.$confirm("此操作将永久删除图书该章节, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.removeName(
            node,
            data,
            (function(node, data) {
              const parent = node.parent;
              const children = parent.data.Child || parent.data;
              const index = children.findIndex(d => d.Id === data.Id);
              children.splice(index, 1);
            })(node, data)
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    async addName(value, data, sub) {
      try {
        var _this = this;
        const result = await booksSectionAddRequest({
          bookid: _this.book.Id,
          secid: (data && data.Id) || "",
          section: value,
          opt: sub
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: `${result.message}`
          });
          if (sub) {
            //新建子目录
            const newChild = {
              Id: result.data.secId,
              Section: value,
              Child: [],
              Trials: 2,
              Bookid: _this.book.Id
            };
            if (!data.Child) {
              this.$set(data, "Child", []);
            }
            data.Child.push(newChild);
          } else if (data && data.Id) {
            data.Section = value;
            console.log(value)
             this.$emit('changeame', value)
            //目录重命名
          } else {
            //新建目录
            const newChild = {
              Id: result.data.secId,
              Section: value,
              Child: [],
              Trials: 2,
              Bookid: _this.book.Id
            };
            _this.section.push(newChild);
          }
        }
      } catch (err) {}
    },
    handelAddName(node, data, sub) {
      var _this = this;
      this.sub = sub || "";
      this.$prompt("请输入目录名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{2,60}$/,
        inputErrorMessage: "长度在 2 到 60 个字"
      })
        .then(({ value }) => {
          _this.addName(value, data, this.sub);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已经取消"
          });
        });
    },
    findIndexFn(arr, id) {
      var _this = this;
      var index = arr.forEach(function(item, index, tarr) {
        if (item.Id == id) {
          tarr.splice(index, 1);
          return true
        } else {
          if (item.Child.length) {
            _this.findIndexFn(item.Child, id);
          }
        }
      });
    }
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
    name() {
      return this.$route.params.name;
    },
    Id() {
      if (this.$route.query.Id == undefined && this.section.length > 0) {
        this.$router.push({
          name: "bookDetail",
          params:{name:this.name},
          query:{Id: this.section[0].Id}
        });
      }
      return this.$route.query.Id;
    }
    
  },
  watch: {
    del: {
      handler(newName, oldName) {
        var _this = this;
        var data = {
          Bookid: this.book.Id,
          Id: this.Id
        };
        this.$confirm("此操作将永久删除图书该章节, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            _this.removeName(
              "",
              data,
              (function(data) {
                _this.findIndexFn(_this.section, data.Id);
              })(data)
            );
            this.$router.push({
              name: "bookDetail",
              query: { Id: this.section[0].Id }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      },
      immediate: false
    }
  }
};
</script>
<style  lang="less" scoped>
.asidesecond {
  position: relative;
  background: #fbfbfb;
  border-right: 1px solid #ccc;
  opacity: 1;
  width: 260px !important;
  padding: 20px;
  transition: all 0.2s;
  .toptxt {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px 0 10px;
    h2 {
      font-size: 16px;
      line-height: 20px;
    }
    .iconfont {
      font-size: 18px;
      color: #000;
      margin-right: 7px;
    }
    .right {
      float: right;
      font-size: 13px;
      color: #3385ff;
      cursor: pointer;
    }
  }
  .centerbox {
    .btnbox {
      margin: 10px auto;
      .iconfont {
        font-size: 12px;
        color: #3385ff;
      }
      .newml {
        font-size: 13px;
        color: #3385ff;
        float: right;
        cursor: pointer;
      }
    }
    /deep/.el-tree {
      background: none;
    }
    .custom-tree-node {
      font-size: 13px;
      width: 100%;
      display: block;
      padding: 0 5px;
      line-height: 20px;
      &::before {
        content: "";
        width: 3px;
        height: 3px;
        background: #ccc;
        margin: 10px 10px 10px 0;
        float: left;
      }
    }
    /deep/.el-tree-node__content > .el-tree-node__expand-icon {
      margin-right: -20px;
      font-size: 15px;
    }
    /deep/.el-tree-node__content {
      cursor: default;
    }
    /deep/.el-tree-node > .el-tree-node__children {
      overflow: initial;
    }
    .custom-tree-node.activenode {
      background-color: #f5f7fa;
    }
    
    .morbox {
      cursor: default;
    }
    .text{
      cursor: pointer;
      &>span{
        max-width: 116px;
        float: left;
        line-height: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

    }
    .morbox {
      position: relative;
      float: right;
      cursor: pointer;
      .moreul {
        display: none;
        background: #fff;
        color: #666;
        border: 1px solid #ececec;
        width: 110px;
        line-height: 34px;
        right: -3px;
        top: 20px;
        position: absolute;
        z-index: 10;
        li {
          border-bottom: 1px solid #ececec;
          margin-bottom: -1px;
          padding: 0 10px;
          font-size: 13px;
          text-align: center;
          cursor: pointer;
          &:hover {
            background: #ecf5ff;
          }
        }
        &::after {
          content: "";
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 10px solid #ececec;
          position: absolute;
          top: -11px;
          right: 2px;
        }
        &::before {
          content: "";
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 10px solid #fff;
          position: absolute;
          top: -9px;
          z-index: 1;
          right: 2px;
        }
      }
    }
    .active .moreul {
      display: block;
    }
  }
}

.asidesecond.hideaside {
  opacity: 0;
  width: 0px !important;
  padding: 0;
}
.custom-tree-node .read{
    background: #EBF1FF;
    color: #3579FF;
    font-size: 10px;
    padding: 0 2px;
    border-radius: 4px;
        margin: 2px 0 0 5px;
    float: left;
}
.morbox:hover {
  .active{
  .moreul{
    display: block;
  }
  }

}
 .moreul:hover{
display: block;
}
</style>